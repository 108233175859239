import { handleSubmitError } from '@/components/notification/defaults';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormHelperText, FormLabel, Grid, IconButton } from '@mui/material';
import { Formik, Form as FormikForm, getIn } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import * as yup from 'yup';

export const FormContext = React.createContext(undefined);

export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customValidation: {},
      initialValues: {},
      validationSchema: {},
    };
  }

  getFormContext = (formikProps) => {
    return {
      values: formikProps.values,
      errors: formikProps.errors,
      touched: formikProps.touched,
      initialValues: formikProps.initialValues,
      setFieldValue: formikProps.setFieldValue,
      setFieldTouched: formikProps.setFieldTouched,
      setCustomError: (name, error) => {
        this.setCustomErrorState(name, error, () => {
          formikProps.validateForm();
        });
      },
      setInitialValue: this.setInitialValue,
      setValidationSchema: this.setValidationSchema,
      validate: formikProps.validateForm,
      isSubmitting: formikProps.isSubmitting,
      isValid: formikProps.isValid,
    };
  };

  setCustomErrorState = (name, error, callback) => {
    this.setState((state) => {
      if (error == null && state.customValidation[name] != null) {
        delete state.customValidation[name];
      } else if (error != null) {
        state.customValidation[name] = error;
      }
      return state;
    }, callback);
  };

  setInitialValue = (name, value) => {
    this.setState((state) => {
      if (value == null) {
        delete state.initialValues[name];
      }
      state.initialValues[name] = value;
      return state;
    });
  };

  setValidationSchema = (name, schema) => {
    this.setState((state) => {
      if (schema == null) {
        delete state.validationSchema[name];
      }
      state.validationSchema[name] = schema;
      return state;
    });
  };

  render() {
    const submit = (values, formikBag) => {
      const submitProps = values["__UNSET__submit_props"]
      delete values["__UNSET__submit_props"]
      return this.props
        .handleSubmit(values, submitProps)
        .then(() => {
          if (!this.props.disableSuccessToast)
            enqueueSnackbar('Sucesso!', {variant: 'success'});
        })
        .catch((error) => {
          handleSubmitError(error, formikBag.setFieldError);
        }).finally(() => {
          formikBag.setFieldValue("__UNSET__submit_props", undefined)
        });
    };

    return (
      <Formik
        onSubmit={submit}
        initialValues={this.state.initialValues}
        validationSchema={yup.object().shape(this.state.validationSchema)}
        validateOnMount
        validate={() => this.state.customValidation}
      >
        {(formikProps) => {
          return (
            <FormikForm>
              <FormContext.Provider value={this.getFormContext(formikProps)}>
                {this.props.children}
              </FormContext.Provider>
            </FormikForm>
          );
        }}
      </Formik>
    );
  }
}

export class FormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationSchemaChildren: {},
      prevContextValue: null,
      removedRows: [],
    };
  }
  buildRow = (index) => {
    let shouldShowDeleteButton =
      !this.props.disableDeleteButton &&
      (!this.props.minRows || index >= this.props.minRows);
    return (
      <Grid
        key={this.translateToFullIndex(index)}
        item
        xs={this.props.itemGridSize ?? 12}
      >
        <Grid
          container
          spacing={this.props.itemGridSpacing ?? 1}
          sx={{width: '100%'}}
        >
          <FormContext.Provider value={this.getFormContext(index)}>
            {this.props.children}
          </FormContext.Provider>
          <Grid item container xs="auto" alignItems="flex-start">
            {shouldShowDeleteButton ? (
              <IconButton
                sx={{p: 0, mt: '22px'}}
                size="small"
                onClick={() => this.removeRow(index)}
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              !this.props.disableDeleteButton && <Grid sx={{px: '12px'}} />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  translateToFullIndex = (index) => {
    let removedRows = this.state.removedRows;
    for (let removedIndex of removedRows) {
      if (removedIndex <= index) index++;
    }
    return index;
  };

  removeRow = (index) => {
    const value = getIn(this.context.values, this.props.name);
    if (index == null) index = value.length - 1;

    let valueFiltered = value.filter((_, i) => i != index);

    if (this.isEmpty()) valueFiltered = [...valueFiltered, {}];

    this.context.setFieldValue(this.props.name, valueFiltered);
    this.setState({
      removedRows: [
        ...this.state.removedRows,
        this.translateToFullIndex(index),
      ],
    });
  };

  addRow = () => {
    if (this.isFull()) return;

    let newValues;
    if (!this.context.values?.[this.props.name]) newValues = [{}];
    else newValues = [...this.context.values[this.props.name], {}];
    this.context.setFieldValue(this.props.name, newValues);
  };

  isFull = () => {
    return (
      this.props.maxRows &&
      getIn(this.context.values, this.props.name)?.length >= this.props.maxRows
    );
  };

  isFilled = () => {
    return getIn(this.context.values, this.props.name).every(
      (elem) => JSON.stringify(elem) != '{}'
    );
  };

  isEmpty = () => {
    return (
      this.props.minRows &&
      getIn(this.context.values, this.props.name)?.length <= this.props.minRows
    );
  };

  getFormContext = (index) => {
    const prefix = `${this.props.name}[${index}]`;
    return {
      formGroupIndex: index,
      values: getIn(this.context.values, this.props.name)?.[index],
      errors: getIn(this.context.errors, this.props.name)?.[index],
      touched: getIn(this.context.touched, this.props.name)?.[index],
      initialValues: getIn(this.context.initialValues, this.props.name)?.[
        this.translateToFullIndex(index)
      ],
      setFieldValue: (name, value) => {
        this.context.setFieldValue(`${prefix}.${name}`, value);
      },
      setFieldTouched: (name, value) => {
        this.context.setFieldTouched(`${prefix}.${name}`, value);
      },
      setCustomError: (name, error) => {
        this.context.setCustomError(`${prefix}.${name}`, error);
      },
      setInitialValue: (name, value) => {
        this.context.setInitialValue(`${prefix}.${name}`, value);
      },
      setValidationSchema: (name, schema) => {
        this.setState(
          (state) => {
            if (schema == null) {
              delete state.validationSchemaChildren[name];
            }
            state.validationSchemaChildren[name] = schema;
            return state;
          },
          () => {
            this.context.setValidationSchema(
              this.props.name,
              this.props.validation
                ? this.props.validation.of(
                    yup.object().shape(this.state.validationSchemaChildren)
                  )
                : yup
                    .array()
                    .of(yup.object().shape(this.state.validationSchemaChildren))
            );
          }
        );
      },
      validate: this.context.validate,
      isSubmitting: this.context.isSubmitting,
      isValid: this.context.isValid,
    };
  };

  getError = () => {
    return (
      Boolean(this.context.touched[this.props.name]) &&
      typeof getIn(this.context.errors, this.props.name) == 'string'
    );
  };

  getHelperText = () => {
    return this.getError() ? getIn(this.context.errors, this.props.name) : '';
  };

  componentDidMount() {
    this.updateInitialValue();
    this.updateChangeListener();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.initialValue) !==
      JSON.stringify(prevProps.initialValue)
    ) {
      this.updateInitialValue();
    }
    this.updateChangeListener();
  }

  updateInitialValue = () => {
    let values;
    if (!this.props.initialValue || !this.props.initialValue.length)
      values = [{}];
    else values = this.props.initialValue;

    if (values.length < this.props.minRows)
      values = [
        ...values,
        ...Array(this.props.minRows - values.length).fill({}),
      ];
    if (values.length > this.props.maxRows)
      values = values.slice(0, this.props.maxRows);

    this.context.setInitialValue(this.props.name, values);
    this.context.setFieldValue(this.props.name, values);
  };

  updateChangeListener = () => {
    if (
      this.props.changeListener &&
      getIn(this.context.values, this.props.name)?.length !=
        this.state.prevContextValue?.length
    )
      this.props.changeListener(getIn(this.context.values, this.props.name));
    if (
      JSON.stringify(getIn(this.context.values, this.props.name)) !==
      JSON.stringify(this.state.prevContextValue)
    )
      this.setState({
        prevContextValue: getIn(this.context.values, this.props.name),
      });
  };

  //Isso é necessário para garantir que a validação rode depois que um componente é desmontado. Um pouco gambiarra (devido ao strict mode também), mas foi o que deu pra fazer
  componentWillUnmount() {
    this.context.setInitialValue(this.props.name, undefined);
    this.context.setValidationSchema(this.props.name, undefined);
    this.context.setFieldValue(this.props.name, undefined);
    setTimeout(() => this.context.validate(), 300);
  }

  render() {
    let inputs;
    const values = getIn(this.context.values, this.props.name);
    const currentGroupSize = values?.length ?? 0;
    if (currentGroupSize) {
      inputs = values.map((elem, index) =>
        this.buildRow(index, currentGroupSize)
      );
    }
    let shouldShowAddButton = !this.props.disableAddButton && !this.isFull();

    return (
      <>
        {this.props.label ? (
          <FormLabel sx={{mb: '5px'}}>{this.props.label}</FormLabel>
        ) : null}
        <Grid container>{inputs}</Grid>
        <Grid container>
          <Grid item xs>
            {this.getError() && (
              <FormHelperText error>{this.getHelperText()}</FormHelperText>
            )}
          </Grid>
          {shouldShowAddButton ? (
            <Grid item xs="auto">
              <IconButton
                sx={{p: 0, mt: '10px'}}
                size="small"
                onClick={() => this.addRow()}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  }
}
FormGroup.contextType = FormContext;
