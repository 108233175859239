import { downloadFile, getFile, uploadFile, validateFile } from '@/util/file';
import { blobToDataUrl, getCroppedImg } from '@/util/image';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import Image from 'next/image';
import { Img } from '../general/Image';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getIn } from 'formik';
import React from 'react';
import { SketchPicker } from 'react-color';
import Cropper from 'react-easy-crop';
import { useIMask } from 'react-imask';
import { FormContext } from './Form';
import { RichTextFunctionalInput } from './SlateUtils';

export const withFormContext = (Component) => {
  //Componente feito para fazer com que os rerenders do form não sejam feitos em todos os componentes, apenas nos que realmente precisam
  const MemoComponent = React.memo(Component);
  // eslint-disable-next-line react/display-name
  return (props) => {
    const context = React.useContext(FormContext);
    const setValue = React.useMemo(
      () =>
        (value, supressChangeListener = false) => {
          context.setFieldValue(props.name, value);
          if (props.changeListener && !supressChangeListener) {
            props.changeListener(value, props);
          }
        },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.name, props.changeListener, context.formGroupIndex]
    );
    const setTouched = React.useMemo(
      () => () => {
        context.setFieldTouched(props.name);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.name, context.formGroupIndex]
    );
    const setCustomError = React.useMemo(
      () => (error) => {
        context.setCustomError(props.name, error);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.name, context.formGroupIndex]
    );
    const onBlur = React.useMemo(
      () => () => {
        setTouched();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [context.formGroupIndex]
    );
    const setInitialValue = React.useMemo(
      () => (value) => {
        context.setInitialValue(props.name, value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.name, context.formGroupIndex]
    );
    const setValidationSchema = React.useMemo(
      () => (validation) => {
        context.setValidationSchema(props.name, validation);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.name, context.formGroupIndex]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validate = React.useMemo(() => context.validate, []);

    return (
      <MemoComponent
        {...props}
        formGroupIndex={context.formGroupIndex}
        value={getIn(context.values, props.name)}
        touched={getIn(context.touched, props.name)}
        error={
          getIn(context.touched, props.name) &&
          Boolean(getIn(context.errors, props.name))
        }
        helperText={
          getIn(context.touched, props.name)
            ? getIn(context.errors, props.name)
            : ''
        }
        initialValue={
          props.initialValue ?? getIn(context.initialValues, props.name)
        }
        setValue={setValue}
        setTouched={setTouched}
        setCustomError={setCustomError}
        setInitialValue={setInitialValue}
        setValidationSchema={setValidationSchema}
        onBlur={onBlur}
        validate={validate}
      />
    );
  };
};

export class CustomFormInput extends React.Component {
  //Isso é necessário para garantir que Inputs que sejam montados depois que o form é montado tenham o valor inicial setado
  componentDidMount() {
    if (this.props.initialValue != null) {
      this.props.setInitialValue(this.props.initialValue);
      this.props.setValue(this.props.initialValue, true);
    }
    if (this.props.validation != null)
      this.props.setValidationSchema(this.props.validation);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.initialValue) !==
      JSON.stringify(prevProps.initialValue)
    ) {
      this.props.setInitialValue(this.props.initialValue);
      this.props.setValue(this.props.initialValue, true);
    }
    if (
      this.props.options &&
      !prevProps.options &&
      JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)
    ) {
      this.props.setValue(undefined);
    }
  }

  //Isso é necessário para garantir que a validação rode depois que um componente é desmontado. Um pouco gambiarra (devido ao strict mode também), mas foi o que deu pra fazer
  componentWillUnmount() {
    if (this.props.formGroupIndex == null) {
      this.props.setInitialValue(undefined);
      this.props.setValidationSchema(undefined);
      this.props.setValue(undefined, true);
    }
    setTimeout(() => this.props.validate(), 300);
  }
}

function extractOtherProps(props) {
  const excludeProps = [
    'value',
    'touched',
    'error',
    'helperText',
    'initialValue',
    'setValue',
    'setTouched',
    'setCustomError',
    'setInitialValue',
    'setValidationSchema',
    'onBlur',
    'validate',
    'changeListener',
    'optionFunction',
    'formGroupIndex',
  ];
  const otherProps = {};
  for (const prop in props) {
    if (!excludeProps.includes(prop)) {
      otherProps[prop] = props[prop];
    }
  }
  return otherProps;
}

class TextInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <TextField
        fullWidth
        name={this.props.name}
        label={this.props.label}
        value={this.props.value ?? ''}
        onChange={(event) => {
          this.props.setValue(event.target.value);
        }}
        onBlur={this.props.onBlur}
        error={this.props.error}
        helperText={this.props.helperText}
        type={this.props.type ?? 'text'}
        {...extractOtherProps(this.props)}
      />
    );
  }
}
export const TextInput = withFormContext(TextInputUnwrapped);

class RichTextInputUnwrapped extends CustomFormInput {
  render() {
    let toolbarButtons = this.props.toolbarButtons ?? [
      'bold',
      'italic',
      'underline',
      'code',
      'heading-one',
      'heading-two',
      'numbered-list',
      'bulleted-list',
      'left',
      'center',
      'right',
      'justify',
    ];

    return (
      <FormControl error={this.props.error} sx={{my: 0}}>
        {this.props.label && (
          <FormLabel id={'controlled-richtext-field-' + this.props.name}>
            {this.props.label}
          </FormLabel>
        )}
        <RichTextFunctionalInput
          name={this.props.name}
          value={this.props.value}
          initialValue={this.props.initialValue}
          error={this.props.error}
          helperText={this.props.helperText}
          onBlur={this.props.onBlur}
          onChange={(value) => {
            this.props.setTouched();
            this.props.setValue(value);
          }}
          toolbarButtons={toolbarButtons}
          {...extractOtherProps(this.props)}
        />
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const RichTextInput = withFormContext(RichTextInputUnwrapped);

/*
Como usar o componente MaskedTextInput:
A mask vem a biblioteca imask-react. Veja a documentação em https://imask.js.org/guide.html#masked-pattern para ver como criar máscaras

Definição dos padrões:
0: aceita qualquer número de 0 a 9
a: aceita qualquer letra de a a z - maiúscula ou minúscula
*: aceita qualquer caractere
[]: tudo dentro dos colchetes é opcional
`: previne "symbol shiftback" - útil quando se tem um caractere opcional no final da máscara
\\: escapa o caractere seguinte

*/

class MaskedTextInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <MaskedTextHiddenInput
        mask={this.props.mask}
        name={this.props.name}
        label={this.props.label}
        value={this.props.value}
        error={this.props.error}
        helperText={this.props.helperText}
        onBlur={this.props.onBlur}
        setValue={this.props.setValue}
        setCustomError={this.props.setCustomError}
        initialValue={this.props.initialValue}
        {...extractOtherProps(this.props)}
      />
    );
  }
}
export const MaskedTextInput = withFormContext(MaskedTextInputUnwrapped);

const MaskedTextHiddenInput = (props) => {
  const {
    name,
    label,
    value,
    error,
    helperText,
    onBlur,
    setValue,
    setTouched,
    setCustomError,
    initialValue,
    validation,
    mask,
    ...otherProps
  } = props;
  const {ref} = useIMask(
    {mask: mask, lazy: false},
    {
      onAccept: (value, mask) => {
        setValue?.(value); // Pode ser um bug, mas já cheguei nessa linha sem existir setValue...
        if (mask.masked.isComplete) setCustomError?.(null);
        else setCustomError?.(label + ' deve estar no formato ' + mask.mask);
      },
    }
  );

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      value={value ?? ''}
      error={error}
      onBlur={onBlur}
      helperText={helperText}
      variant="outlined"
      inputRef={ref}
      {...otherProps}
    />
  );
};

class SelectSingleInputUnwrapped extends CustomFormInput {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      optionsLoading: false,
    };
    this.onInputChange = debounce(this.rawOnInputChange, 300);
  }

  rawOnInputChange = (event, newValue) => {
    if (this.props.optionFunction) {
      this.setState({optionsLoading: true});
      this.props
        .optionFunction(newValue)
        .then((options) => {
          this.setState({options: options, optionsLoading: false});
        })
        .catch(() => {
          this.setState({options: [], optionsLoading: false});
        });
    }
  };

  render() {
    const options = this.state.options ?? this.props.options ?? [];
    const filterOptionProp = this.props.optionFunction
      ? {filterOptions: (x) => x, noOptionsText: 'Digite para pesquisar'}
      : {};
    const selectedOption = this.props.options?.find(
      (option) => getValueFromOption(option) === this.props.value
    );

    return (
      <Autocomplete
        sx={this.props.sx}
        options={options}
        name={this.props.name}
        autoHighlight={true}
        label={this.props.label}
        value={getOptionFromValue(options, this.props.value)}
        onChange={(event, option) => {
          this.props.setValue(getValueFromOption(option));
        }}
        isOptionEqualToValue={(option, value) => {
          return getValueFromOption(option) == getValueFromOption(value);
        }}
        getOptionLabel={(option) => {
          return option?.label;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.imageUrl && (
              <Image
                src={option.imageUrl}
                alt={option.label}
                style={{marginRight: '10px'}}
                width={40}
                height={40}
              />
            )}
            {option.label}
          </li>
        )}
        fullWidth
        onInputChange={this.onInputChange}
        {...filterOptionProp}
        loading={this.state.optionsLoading}
        renderInput={(params) => {
          return (
            <TextField
              label={this.props.label}
              onBlur={() => {
                this.props.setTouched();
              }}
              error={this.props.error}
              helperText={this.props.helperText}
              {...params}
              InputProps={{
                ...params.InputProps,
                ...this.props.InputProps, //Importante ser nessa ordem para reescrever o undefined padrão
                startAdornment:
                  selectedOption && selectedOption.imageUrl ? (
                    <InputAdornment position="start">
                      <Image
                        src={selectedOption?.imageUrl}
                        alt={selectedOption?.label}
                        style={{marginRight: '10px'}}
                        width={40}
                        height={40}
                      />
                    </InputAdornment>
                  ) : null,
              }}
            />
          );
        }}
      />
    );
  }
}
export const SelectSingleInput = withFormContext(SelectSingleInputUnwrapped);

class SelectMultipleInputUnwrapped extends CustomFormInput {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      optionsLoading: false,
    };
    this.onInputChange = debounce(this.rawOnInputChange, 300);
  }

  onChangeSelectMultiple = (event, options, reason) => {
    this.props.setTouched(false);
    switch (reason) {
      case 'selectOption':
        this.props.setValue(
          options.map((option) => getValueFromOption(option))
        );
        break;
      case 'removeOption':
        this.props.setValue(
          options.map((option) => getValueFromOption(option))
        );
        break;
      case 'clear':
        this.props.setValue([]);
        break;
      case 'createOption':
        this.props.setValue(
          options.map((option) => getValueFromOption(option))
        );
        break;
    }
  };

  rawOnInputChange = (event, newValue) => {
    if (this.props.optionFunction) {
      this.setState({optionsLoading: true});
      this.props
        .optionFunction(newValue)
        .then((options) => {
          this.setState({options: options, optionsLoading: false});
        })
        .catch(() => {
          this.setState({options: [], optionsLoading: false});
        });
    }
  };

  render() {
    const options = this.state.options ?? this.props.options ?? [];
    const filterOptionProp = this.props.optionFunction
      ? {filterOptions: (x) => x, noOptionsText: 'Digite para pesquisar'}
      : {};

    return (
      <Autocomplete
        sx={this.props.sx}
        multiple
        options={options}
        name={this.props.name}
        autoHighlight={true}
        label={this.props.label}
        value={
          this.props.value
            ? this.props.value.map((value) => {
                return getOptionFromValue(options, value);
              })
            : []
        }
        {...filterOptionProp}
        loading={this.state.optionsLoading}
        onChange={this.onChangeSelectMultiple}
        getOptionLabel={(option) => {
          return option?.label ?? '';
        }}
        fullWidth
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                key={index}
                color={option?.color}
                label={option?.label}
                {...getTagProps({index})}
              />
            );
          })
        }
        renderInput={(params) => {
          return (
            <TextField
              label={this.props.label}
              onBlur={() => {
                this.props.setTouched();
              }}
              error={this.props.error}
              helperText={this.props.helperText}
              {...params}
              InputProps={{
                ...params.InputProps,
                ...this.props.InputProps, //Importante ser nessa ordem para reescrever o undefined padrão
              }}
            />
          );
        }}
      />
    );
  }
}
export const SelectMultipleInput = withFormContext(
  SelectMultipleInputUnwrapped
);

function getOptionFromValue(options, value) {
  return (
    options.find((option) => {
      return option.value == value;
    }) ?? null
  );
}

function getValueFromOption(option) {
  return option?.value ?? '';
}

class RadioInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <FormControl error={this.props.error}>
        {this.props.label && (
          <FormLabel id={'controlled-radio-buttons-group-' + this.props.name}>
            {this.props.label}
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby={'controlled-radio-buttons-group-' + this.props.name}
          name={name}
          value={this.props.value ?? null}
          row={this.props.direction == 'row'}
          onChange={(event, value) => {
            this.props.setTouched();
            this.props.setValue(event.target.value);
          }}
        >
          {this.props.options.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
                labelPlacement={this.props.labelPlacement ?? 'end'}
              />
            );
          })}
        </RadioGroup>
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const RadioInput = withFormContext(RadioInputUnwrapped);

export const InputCard = (props) => {
  const color = props.color ?? 'primary.main';
  const disabled = props.disabled;
  return (
    <Card
      sx={{
        backgroundColor: props.active ? 'neutral.200' : 'neutral.0',
        color: props.active ? 'black' : 'neutral.700',
        border: 2,
        borderColor: props.active ? color : 'neutral.500', //Border-color deve vir depois do border
        cursor: !disabled ? 'pointer' : '',
        p: '11px',
      }}
    >
      <Grid container direction="row" alignItems="center">
        {props.icon ? (
          <Grid item xs="auto">
            <Grid container alignItems="center" sx={{height: '100%'}}>
              <Avatar
                sx={{bgcolor: props.active ? color : 'neutral.500', mx: '8px'}}
              >
                {props.icon}
              </Avatar>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs>
          <Typography
            variant="label-medium"
            sx={{display: 'block', cursor: 'inherit'}}
          >
            {props.title}
          </Typography>
          {props.description ? (
            <Typography variant="body-tiny" sx={{display: 'block'}}>
              {props.description}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
};

class RadioCardInputUnwrapped extends CustomFormInput {
  render() {
    const columnSize = calculateColumnSize(
      this.props.maxColumns,
      this.props.children.length
    );
    const handleClick = (value) => {
      this.props.setTouched();
      this.props.setValue(value);
    };
    return (
      <>
        {this.props.label ? <FormLabel>{this.props.label}</FormLabel> : null}

        <Grid container spacing={1} sx={{mt: '1px'}}>
          {this.props.children.map((option, index) => {
            return (
              <Grid
                item
                key={index}
                xs={columnSize}
                onClick={() => {
                  !this.props.disabled
                    ? handleClick(option.props.value)
                    : undefined;
                }}
              >
                {React.cloneElement(option, {
                  active: this.props.value == option.props.value,
                  color: option.props.color ?? this.props.color,
                  disabled: this.props.disabled,
                })}
              </Grid>
            );
          })}
        </Grid>
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </>
    );
  }
}
export const RadioCardInput = withFormContext(RadioCardInputUnwrapped);

class CheckboxCardInputUnwrapped extends CustomFormInput {
  render() {
    const escapedValue = this.props.value ?? [];
    const columnSize = calculateColumnSize(
      this.props.maxColumns,
      this.props.children.length
    );

    const isCheckboxChecked = (value, option) => {
      return value?.includes(option.value) ?? false;
    };
    //TODO adicionar debouce
    const handleCheckboxChange = (event, option) => {
      this.props.setTouched();
      if (!isCheckboxChecked(escapedValue, option)) {
        this.props.setValue([...escapedValue, option.value]);
      } else {
        this.props.setValue(
          escapedValue.filter((value) => {
            return value != option.value;
          })
        );
      }
    };

    return (
      <>
        {this.props.label ? <FormLabel>{this.props.label}</FormLabel> : null}

        <Grid container spacing={1} sx={{mt: '1px'}}>
          {this.props.children.map((option, index) => {
            return (
              <Grid
                item
                key={index}
                xs={columnSize}
                onClick={(event) => {
                  !this.props.disabled
                    ? handleCheckboxChange(event, option.props)
                    : undefined;
                }}
              >
                {React.cloneElement(option, {
                  active: isCheckboxChecked(escapedValue, option.props),
                  color: option.props.color ?? this.props.color,
                  disabled: this.props.disabled,
                })}
              </Grid>
            );
          })}
        </Grid>
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </>
    );
  }
}
export const CheckboxCardInput = withFormContext(CheckboxCardInputUnwrapped);

function calculateColumnSize(maxColumns, childrenLength) {
  const allowedValues = [1, 2, 3, 4, 6, 12];
  if (!maxColumns) maxColumns = 12;
  if (!childrenLength) childrenLength = 1;

  const columnSize = 12 / Math.min(maxColumns, childrenLength);
  return allowedValues.find((value) => value >= columnSize);
}

class CheckboxChipInlineInputUnwrapped extends CustomFormInput {
  render() {
    const isCheckboxChecked = (option) => {
      return this.props.value?.includes(option.value) ?? false;
    };
    const handleCheckboxChange = (option) => {
      this.props.setTouched();
      if (!isCheckboxChecked(option)) {
        this.props.setValue([...this.props.value, option.value]);
      } else {
        this.props.setValue(
          this.props.value?.filter((value) => {
            return value != option.value;
          })
        );
      }
    };
    return (
      <FormControl sx={{display: 'inline-block'}} error={this.props.error}>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        <Stack direction="row" spacing={1}>
          {this.props.options.map((option, index) => {
            return (
              <Chip
                label={option.label}
                key={index}
                variant={isCheckboxChecked(option) ? 'filled' : 'outlined'}
                onClick={(event) => {
                  handleCheckboxChange(option);
                }}
                color={isCheckboxChecked(option) ? this.props.color : undefined}
                sx={{cursor: 'pointer', height: '24px'}}
              />
            );
          })}
        </Stack>
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const CheckboxChipInlineInput = withFormContext(
  CheckboxChipInlineInputUnwrapped
);

class SwitchInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <FormControl error={this.props.error} {...extractOtherProps(this.props)}>
        <FormControlLabel
          control={
            <Switch
              sx={{mr: 1}}
              name={this.props.name}
              checked={this.props.value ?? false}
              onChange={(event) => {
                this.props.setTouched();
                this.props.setValue(event.target.checked);
              }}
              icon={this.props.icon}
              checkedIcon={this.props.checkedIcon}
            />
          }
          label={this.props.label}
          sx={{m: 0}}
        />
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const SwitchInput = withFormContext(SwitchInputUnwrapped);

class CheckboxInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <FormControl error={this.props.error}>
        <FormControlLabel
          control={
            <Checkbox
              name={this.props.name}
              checked={this.props.value ?? false}
              onChange={(event) => {
                this.props.setTouched();
                this.props.setValue(event.target.checked);
              }}
            />
          }
          label={this.props.label}
        />
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const CheckboxInput = withFormContext(CheckboxInputUnwrapped);

class CheckboxGroupInputUnwrapped extends CustomFormInput {
  render() {
    const isCheckboxChecked = (value, option) => {
      return value?.includes(option.value) ?? false;
    };
    const handleCheckboxChange = (event, option) => {
      this.props.setTouched();
      if (event.target.checked) {
        this.props.setValue([...this.props.value, option.value]);
      } else {
        this.props.setValue(
          this.props.value.filter((value) => {
            return value != option.value;
          })
        );
      }
    };
    return (
      <FormControl error={this.props.error}>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        {this.props.options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={isCheckboxChecked(this.props.value, option)}
                  onChange={(event) => {
                    handleCheckboxChange(event, option);
                  }}
                />
              }
              label={option.label}
            />
          );
        })}
        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
export const CheckboxGroupInput = withFormContext(CheckboxGroupInputUnwrapped);

class DateInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <DatePicker
        name={this.props.name}
        label={this.props.label}
        value={this.props.value ? dayjs(this.props.value) : null}
        onChange={(value, context) => {
          this.props.setValue(value);
        }}
        onClose={() => this.props.setTouched(false)}
        slotProps={{
          textField: {
            onBlur: () => this.props.setTouched(),
            error: this.props.error,
            helperText: this.props.helperText,
          },
        }}
        {...extractOtherProps(this.props)}
      />
    );
  }
}
export const DateInput = withFormContext(DateInputUnwrapped);

class TimeInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <TimePicker
        name={this.props.name}
        label={this.props.label}
        value={this.props.value ?? null}
        onChange={(value, context) => {
          this.props.setValue(value);
        }}
        onClose={() => this.props.setTouched(false)}
        error={this.props.error}
        slotProps={{
          textField: {
            onBlur: () => this.props.setTouched(),
            error: this.props.error,
            helperText: this.props.helperText,
          },
        }}
        {...extractOtherProps(this.props)}
      />
    );
  }
}
export const TimeInput = withFormContext(TimeInputUnwrapped);

class DateTimeInputUnwrapped extends CustomFormInput {
  render() {
    return (
      <DateTimePicker
        name={this.props.name}
        label={this.props.label}
        value={this.props.value ?? null}
        onChange={(value, context) => {
          this.props.setValue(value);
        }}
        onClose={() => this.props.setTouched(false)}
        error={this.props.error}
        slotProps={{
          textField: {
            onBlur: () => this.props.setTouched(),
            error: this.props.error,
            helperText: this.props.helperText,
          },
        }}
        {...extractOtherProps(this.props)}
      />
    );
  }
}
export const DateTimeInput = withFormContext(DateTimeInputUnwrapped);

class FileInputUnwrapped extends CustomFormInput {
  constructor(props) {
    super(props);
    this.state = {
      backendFileRef: null,
      selectedFile: null,
      isUploading: false,
      fileId: null,
      inputRef: React.createRef(),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.initialValue) {
      getFile(this.props.initialValue).then((file) => {
        this.setState({backendFileRef: file, fileId: this.props.initialValue});
      });
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (this.props.initialValue != prevProps.initialValue) {
      if (this.props.initialValue) {
        getFile(this.props.initialValue).then((file) => {
          this.setState({
            backendFileRef: file,
            fileId: this.props.initialValue,
          });
        });
      } else {
        this.setState({backendFileRef: null, fileId: null});
      }
    }
  }

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    this.setState({selectedFile: file, isUploading: true});

    uploadFile(file, this.props.fileValidation, this.props.filename)
      .then((response) => {
        this.setState({
          isUploading: false,
          fileId: response.data.id,
          backendFileRef: response.data,
        });
        this.props.setCustomError(null);
        this.props.setValue(response.data.id);
        this.props.setTouched();
      })
      .catch((error) => {
        this.setState({selectedFile: null, isUploading: false});
        this.props.setCustomError(
          error?.response?.data?.message ??
            error?.errorMsg ??
            'Ocorreu um erro com o arquivo'
        );
        this.props.setValue(null);
        this.state.inputRef.current.value = null;
        this.props.setTouched();
      });
  };

  handleRemoveFile = async (event) => {
    event.stopPropagation();
    this.setState({
      selectedFile: null,
      fileId: null,
      isUploading: false,
      backendFileRef: null,
    });
    this.state.inputRef.current.value = null;
    this.props.setValue(null);
  };

  render() {
    const handleClick = () => {
      this.state.inputRef.current.click();
    };

    return (
      <>
        <input
          type="file"
          ref={this.state.inputRef}
          onChange={this.handleFileChange}
          style={{display: 'none'}}
          accept={this.props.fileValidation?.type}
        />
        <Grid container>
          <Grid item xs>
            <TextField
              name={this.props.name}
              label={this.props.label}
              value={
                this.state.selectedFile?.name ??
                this.state.backendFileRef?.original_file_name ??
                ''
              }
              onClick={handleClick}
              error={this.props.error}
              helperText={this.props.helperText}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      {this.state.isUploading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <UploadFileIcon />
                      )}
                    </InputAdornment>
                    {this.state.fileId ? (
                      <>
                        <IconButton onClick={this.handleRemoveFile}>
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : null}
                  </>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {this.state.backendFileRef && (
            <Grid item xs="auto" sx={{my: '8px', pl: '8px'}}>
              <Button
                variant="outlined"
                onClick={() => downloadFile(this.state.backendFileRef)}
                sx={{height: '56px'}}
              >
                <DownloadForOfflineIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
export const FileInput = withFormContext(FileInputUnwrapped);

class ColorPickerInputUnwrapped extends CustomFormInput {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleColorChange = (newColor) => {
    this.props.setValue(newColor.hex);
  };

  render() {
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            style={{backgroundColor: this.props.value, width: 56, height: 56}}
            aria-describedby={id}
            variant="contained"
            onClick={this.handleClick}
          />
          <TextField
            fullWidth
            name={this.props.name}
            label={this.props.label}
            value={this.props.value ?? ''}
            onBlur={this.props.onBlur}
            error={this.props.error}
            // helperText={helperText} // O helper está alterando o design de forma estranha, estou tirando por enquanto
            onChange={(event) => {
              this.props.setValue(event.target.value);
              this.props.setTouched();
            }}
            sx={{cursor: 'pointer'}}
            onClick={this.handleClick}
            InputProps={{
              readOnly: true,
            }}
            {...extractOtherProps(this.props)}
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SketchPicker
            color={this.props.value}
            onChangeComplete={this.handleColorChange}
            disableAlpha
          />
        </Popover>
      </>
    );
  }
}
export const ColorPickerInput = withFormContext(ColorPickerInputUnwrapped);

const initialPictureInputState = {
  croppingImg: false,
  selectedFile: null,
  selectedFileDataUrl: null,
  croppedImgFile: null,
  croppedImgDataUrl: null,
  isUploading: false,
  crop: {x: 0, y: 0},
  zoom: 1,
  croppedAreaPixels: null,
};

class PictureInputUnwrapped extends CustomFormInput {
  constructor(props) {
    super(props);
    this.state = {
      ...initialPictureInputState,
      backendFileRef: null,
      fileId: null,
      inputRef: React.createRef(),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.initialValue) {
      getFile(this.props.initialValue).then((file) => {
        this.setState({backendFileRef: file, fileId: this.props.initialValue});
      });
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (this.props.initialValue != prevProps.initialValue) {
      if (this.props.initialValue) {
        getFile(this.props.initialValue).then((file) => {
          this.setState({
            backendFileRef: file,
            fileId: this.props.initialValue,
          });
        });
      } else {
        this.setState({backendFileRef: null, fileId: null});
      }
    }
  }

  handleCropFinish = async () => {
    const croppedImage = await getCroppedImg(
      this.state.selectedFileDataUrl,
      this.state.croppedAreaPixels,
      {
        width: this.props.width,
        height: this.props.height,
      }
    );

    this.setState({croppedImgFile: croppedImage, isUploading: true});
    blobToDataUrl(croppedImage).then((dataUrl) => {
      this.setState({croppedImgDataUrl: dataUrl});
    });

    uploadFile(croppedImage, this.props.fileValidation, this.props.filename)
      .then((response) => {
        this.setState(
          {
            isUploading: false,
            croppingImg: false,
            fileId: response.data.id,
            backendFileRef: response.data,
          },
          () => {
            if (this.props.uploadListener) {
              this.props.uploadListener(response.data.id, response.data);
            }
          }
        );
        this.props.setCustomError(null);
        this.props.setValue(response.data.id);
        this.props.setTouched();
        if (this.props.uploadListener) {
          this.props.uploadListener(response.data.id, response.data);
        }
      })
      .catch((error) => {
        this.setState(initialPictureInputState);
        this.props.setCustomError(
          error?.response?.data?.message ??
            error?.errorMsg ??
            'Ocorreu um erro com o arquivo'
        );
        this.props.setValue(null);
        this.props.setTouched();
        this.state.inputRef.current.value = null;
      });
  };

  handleImageSelect = async (event) => {
    const file = event.target.files[0];
    if (this.props.fileValidation?.type) {
      const {isValid, error} = validateFile(file, {
        type: this.props.fileValidation?.type,
      });
      if (!isValid) {
        this.props.setCustomError(error);
        this.setState(initialPictureInputState);
        this.state.inputRef.current.value = null;
        this.props.setTouched();
        return;
      }
    }

    blobToDataUrl(file).then((dataUrl) => {
      this.setState({
        selectedFileDataUrl: dataUrl,
        selectedFile: file,
        croppingImg: true,
      });
      event.target.value = null;
    });
  };

  handleModalClose = () => {
    this.setState(initialPictureInputState);
    this.state.inputRef.current.value = null;
  };

  handleRemoveFile = () => {
    this.handleModalClose();
    this.setState({backendFileRef: null, fileId: null});
    if (this.props.removeFileListener) {
      this.props.removeFileListener(
        this.props.value,
        this.props.formGroupIndex
      );
    }
  };

  render() {
    const handleClick = (event) => {
      this.state.inputRef.current.click(event);
    };

    return (
      <Box>
        <Dialog
          open={!!this.state.croppingImg}
          onClose={this.handleModalClose}
          PaperProps={{sx: {maxWidth: 'unset'}}}
        >
          <Typography variant="title-large" sx={{mt: '7px', ml: '9px'}}>
            Cortar Imagem
          </Typography>
          <Box
            sx={{height: '50vh', width: '50vw', m: '7px', position: 'relative'}}
          >
            <Cropper
              image={this.state.selectedFileDataUrl}
              crop={this.state.crop}
              cropShape={this.props.cropShape ?? 'rect'} //["rect", "round"]
              zoom={this.state.zoom}
              aspect={this.props.aspectRatio ?? 1}
              onCropChange={(newCrop) => this.setState({crop: newCrop})}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                this.setState({croppedAreaPixels})
              }
              onZoomChange={(newZoom) => this.setState({zoom: newZoom})}
            />
          </Box>
          <Box sx={{justifyContent: 'end', display: 'flex', my: '7px'}}>
            <Button variant="outlined" onClick={this.handleModalClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={this.handleCropFinish}
              sx={{mx: '9px'}}
            >
              Selecionar
            </Button>
          </Box>
        </Dialog>
        <input
          type="file"
          ref={this.state.inputRef}
          onChange={this.handleImageSelect}
          style={{display: 'none'}}
          accept={this.props.fileValidation?.type}
        />
        <FormLabel sx={{mb: '7px'}}>{this.props.label}</FormLabel>
        {this.state.backendFileRef && !this.props.disablePreview ? (
          <Box sx={{display: 'flex'}}>
            <Img
              src={this.state.backendFileRef?.file}
              width={this.props.width}
              alt="CroppedImg"
            />
          </Box>
        ) : null}
        <Stack direction="row" sx={{my: '5px', fontSize: '14px'}} spacing="5px">
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClick}
            sx={{fontSize: 'inherit'}}
          >
            <UploadFileIcon sx={{fontSize: 'inherit', mr: '3px'}} /> Selecionar
            Arquivo
          </Button>
          {this.state.fileId && !this.props.disableRemove ? (
            <Button
              variant="outlined"
              color="error"
              onClick={this.handleRemoveFile}
            >
              <CloseIcon sx={{fontSize: 'inherit'}} />
            </Button>
          ) : null}
        </Stack>

        {this.props.error && (
          <FormHelperText error>{this.props.helperText}</FormHelperText>
        )}
      </Box>
    );
  }
}
export const PictureInput = withFormContext(PictureInputUnwrapped);

class HiddenInputUnwrapped extends CustomFormInput {
  render() {
    const {name, value} = this.props;
    return <input name={name} type="hidden" value={value} />;
  }
}
export const HiddenInput = withFormContext(HiddenInputUnwrapped);
